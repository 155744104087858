<template>
  <!-- 查看完税证明列表 -->
  <div class="tax-for-enterprise">
    <el-dialog
      dialog
      title="完税证明详情"
      :visible.sync="isPreview"
      width="800px"
    >
      <el-table
        :data="tableData"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        v-loading="loading"
      >
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="TaxUrl"
          label="完税凭证"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-link
              v-if="isNotImage(scope.row.TaxUrl)"
              type="primary"
              @click="downFileFn(scope.row.TaxUrl)"
            >{{ scope.row.TaxUrl}}</el-link>
            <el-image
              class="preview-img"
              :src="scope.row.TaxUrl"
              :preview-src-list="[scope.row.TaxUrl]"
              v-else
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CreateTime"
          label="添加时间"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <!-- <el-table-column
          align="center"
          label="操作"
          fixed="right"
          width="150"
        >
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="small"
              @click="shipPreviewClick(scope.row)"
              icon="el-icon-document"
            >
              删除
            </el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        class="pagination"
        @current-change="handleCurrentChange"
        :current-page.sync="pagination.page"
        @size-change="sizeChange"
        :page-size="pagination.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>

      <template #footer>
        <el-button
          type="primary"
          size="small"
          @click="$refs.taxFile.click()"
          v-if="showUploadBtn"
        >
          新增完税凭证
        </el-button>
        <el-button
          size="small"
          @click="isPreview = false"
        >
          关闭
        </el-button>
      </template>

      <!-- 上传 -->
      <input
        type="file"
        style="display: none"
        @change="toUpTaxForEnterprise"
        ref="taxFile"
      />
    </el-dialog>
  </div>
</template>

<script>
import { getDriverTaxPage, putDriverTaxAdd } from '@/api/transport/driverManage/index.js'
import { _getUserId, _getParentRoleId } from "@/utils/storage";
import { upLoadImgByUrl } from '@/api/common/common.js'

export default {
  data() {
    return {
      isPreview: false,
      loading: false,
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      tableData: [], //表格数据
    }
  },
  props: {
    showUploadBtn: {
      type: Boolean,
      default: true
    },
    userInfo: {
      type: Object,
      required: true
    }
  },
  methods: {
    showDialog() {
      this.isPreview = true

      this.$nextTick(() => {
        this.pagination.page = 1
        this.getPage()
      })
    },

    isNotImage(filename) {
      return !/\.(jpg|jpeg|png|gif)$/i.test(filename); // 返回结果为true或false，根据文件名是否符合图像格式后缀进行判断
    },

    // 完税凭证列表
    async getPage() {
      this.tableData = []
      try {
        this.loading = true
        let params = {
          pageSize: this.pagination.pagesize,
          pageIndex: this.pagination.page,
          driverIdCard: this.userInfo.idCard,
        }

        switch (_getParentRoleId()) {
          case '2':
            params.ownerId = parseInt(_getUserId()) / 5
            break;
          case '3':
            params.ownerId = this.userInfo.id
            break;
        }

        _getParentRoleId() == 3 ? (params.supplierId = parseInt(_getUserId()) / 5) : '';

        let { data = {} } = await getDriverTaxPage(params)
        this.tableData = data?.DataList || []
        this.pagination.total = data?.TotalCount || 0
      } finally {
        this.loading = false
      }
    },

    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.getPage()
    },

    //分页数量改变
    sizeChange(e) {
      this.pagination.pagesize = e;
      this.getPage()
    },

    // 上传完税证明
    async toUpTaxForEnterprise(e) {
      const loading = this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      try {
        let file = e.srcElement.files[0] || e.target.files[0]
        let fileType = file.name.split(".").at(-1)

        if (window.FileReader) {
          let reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onload = async (e) => {
            let imgRes = await upLoadImgByUrl({
              FileBase64: e.target.result, FileType: 'AllowFile',
              FileSuffix: '.' + fileType,
            })

            if (!imgRes?.data) {
              return this.$message.error('文件上传失败');
            }

            let params = {
              ownerId: this.userInfo.id,
              driverName: this.userInfo.name,
              driverPhone: this.userInfo.phone,
              driverIdCard: this.userInfo.idCard,
              taxUrl: imgRes.data,
            }

            _getParentRoleId() == 3 ? (params.supplierId = parseInt(_getUserId()) / 5) : '';

            await putDriverTaxAdd(params)
            this.$message.success('上传成功')
            this.getPage()
          }
        } else {
          return this.$message.error('获取文件失败');
        }
      } finally {
        loading.close()
      }
    },

    downFileFn(data) {
      if (data) {
        const el = document.createElement("a");
        el.style.display = "none";
        el.setAttribute("target", "_blank");
        el.setAttribute("download", "");
        el.href = data;
        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);
      } else {
        this.$message.error('模板异常，请联系管理员')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.preview-img {
  width: 50px;
  height: 50px;
}

.pagination {
  margin-top: 8px;
}
</style>